<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <rect fill="#000000" x="2" y="5" width="19" height="4" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="2" y="11" width="19" height="10" rx="1"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Calendar"
}
</script>

<style scoped>

</style>
