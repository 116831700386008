<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.346" height="16" viewBox="0 0 13.346 16">
    <path id="office-365" d="M13.346,14.667h0V1.374L8.583,0,.022,3.215,0,3.219V12.83l2.922-1.143V3.867l5.66-1.353v11.5L.006,12.83,8.582,16v0l4.763-1.318v-.015Z" fill="#b5b5c3" fill-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: "Azure"
}
</script>

<style scoped>

</style>