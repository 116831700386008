<template>
  <!--begin::Management-->
  <div class="d-flex flex-row">
    <div v-if="!floorPlanEditorPage" class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_submenu_aside" ref="kt_submenu_aside">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <!--begin::Nav-->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded"
              role="tablist"
          >
            <p class="text-muted pt-5 pb-2">{{ $t("MANAGEMENT.SPACE_MANAGEMENT_TITLE") }}</p>
            <div v-if="isCan('common-office-view')"
                 @click="setAmplitudeEventType('management-office show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'offices', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <Building/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MANAGEMENT.OFFICES_TITLE") }}</span>
              </router-link>
            </div>
            <div v-if="isCan('common-amenities-view')"
                 @click="setAmplitudeEventType('management-amenity show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'amenities', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <Flower/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MANAGEMENT.AMENITIES_TITLE") }}</span>
              </router-link>
            </div>
<!--            <div class="navi-item mb-2">-->
<!--              <router-link-->
<!--                  class="navi-link py-4"-->
<!--                  :to="{ name: 'resources', exact: true }"-->
<!--                  active-class="active"-->
<!--              >-->
<!--                <span class="navi-icon mr-2">-->
<!--                  <Armchair/>-->
<!--                </span>-->
<!--                <span class="navi-text font-size-lg font-weight-bolder">Ресурсы (теперь в списке этажей Офиса)</span>-->
<!--              </router-link>-->
<!--            </div>-->
            <p class="text-muted pt-5 pb-2">{{ $t("MANAGEMENT.USER_MANAGEMENT_TITLE") }}</p>
            <div v-if="isCan('common-employees-view')"
                 @click="setAmplitudeEventType('management-employee show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'employees', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <User/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MANAGEMENT.EMPLOYEES_TITLE") }}</span>
              </router-link>
            </div>
            <div v-if="isCan('common-employees-groups-view')"
                 @click="setAmplitudeEventType('management-group show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'groups', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <Group/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MANAGEMENT.GROUPS_TITLE") }}</span>
              </router-link>
            </div>
            <div v-if="isCan('management-roles-view')"
                 @click="setAmplitudeEventType('management-role show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'roles', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <ShieldProtected/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MANAGEMENT.ROLES_TITLE") }}</span>
              </router-link>
            </div>
            <p class="text-muted pt-5 pb-2">{{ $t("MANAGEMENT.INTEGRATIONS_TITLE") }}</p>
            <div v-if="isCan('management-integration-pacs-sigur-view')"
                 @click="setAmplitudeEventType('management-integrations-sigur-authorization show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'sigur', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <Shuffle/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">Sigur</span>
              </router-link>
            </div>
            <div v-if="isCan('management-integration-azure-view')"
                 @click="setAmplitudeEventType('management-integrations-azure-authorization show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'azure', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <Azure/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">Microsoft Azure</span>
              </router-link>
            </div>
            <p class="text-muted pt-5 pb-2">{{ $t("MANAGEMENT.OTHER_TITLE") }}</p>
            <div v-if="isCan('common-organization-view')"
                 @click="setAmplitudeEventType('management-organization show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'organization', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <Box/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MANAGEMENT.ORGANIZATION_TITLE") }}</span>
              </router-link>
            </div>
            <div v-if="isCan('management-settings-production-calendar-view')"
                 @click="setAmplitudeEventType('management-settings-production-calendar show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'production-calendar', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <Calendar/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MANAGEMENT.PRODUCTION_CALENDAR_TITLE") }}</span>
              </router-link>
            </div>
          </div>
          <!--end::Nav-->
        </div>
      </div>
    </div>
    <!--begin::Content-->
    <div class="content-wrapper flex-row-fluid" :class="floorPlanEditorPage ? '' : 'ml-lg-8'">
      <transition name="fade-in-up">
        <router-view />
      </transition>
    </div>
    <!--end::Content-->
  </div>
  <!--end::Management-->
</template>

<script>
import Calendar from "@/view/layout/svg/Calendar";
import Box from "@/view/layout/svg/Box";
import Building from "@/view/layout/svg/Building";
import Armchair from "@/view/layout/svg/Armchair";
import User from "@/view/layout/svg/User";
import Flower from "@/view/layout/svg/Flower";
import Group from "@/view/layout/svg/Group";
import ShieldProtected from "@/view/layout/svg/ShieldProtected";
import Azure from "../../layout/svg/Azure";
import Shuffle from "../../layout/svg/Shuffle";
import KTLayoutAside from "@/assets/js/layout/base/aside";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

export default {
  name: "Management",
  components: {
    Calendar,
    Box,
    Building,
    Armchair,
    User,
    Flower,
    Group,
    ShieldProtected,
    Azure,
    Shuffle
  },
  mounted() {
    KTLayoutAside.init(this.$refs["kt_submenu_aside"]);
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    floorPlanEditorPage() {
      return this.$route.name === 'floor-plan-editor';
    }
  },
  methods: {
    isCan(role) {
      if (this.$store.getters.isCan) {
        let findedRole = this.$store.getters.isCan.find(item => item.includes(role));
        return findedRole !== undefined;
      }
    },
    setAmplitudeEventType(eventType) {
      sendAmplitudeEvent(eventType);
    }
  }
};
</script>
